// Anchor Scrolling
function initAnchorScrolling() {
  $('a[href*="#"]')
    // Remove links that don't actually link to anything
    .not('[href="#"]')
    .not('[href="#0"]')
    .click(function (event) {
      // On-page links
      if (
        location.pathname.replace(/^\//, "") ==
          this.pathname.replace(/^\//, "") &&
        location.hostname == this.hostname
      ) {
        // Figure out element to scroll to
        var target = $(this.hash);
        target = target.length
          ? target
          : $("[name=" + this.hash.slice(1) + "]");
        // Does a scroll target exist?
        if (target.length) {
          // Only prevent default if animation is actually gonna happen
          event.preventDefault();
          $("html, body").animate(
            {
              scrollTop: target.offset().top,
            },
            1000,
            function () {
              // Callback after animation
              // Must change focus!
              var $target = $(target);
              $target.focus();
              if ($target.is(":focus")) {
                // Checking if the target was focused
                return false;
              } else {
                $target.attr("tabindex", "-1"); // Adding tabindex for elements not focusable
                $target.focus(); // Set focus again
              }
            }
          );
        }
      }
    });
}

//TABS//
function openGame(evt, gameName) {
  var i, tabcontent, tablinks;
  tabcontent = document.getElementsByClassName("tab__content");
  for (i = 0; i < tabcontent.length; i++) {
    tabcontent[i].style.display = "none";
  }
  tablinks = document.getElementsByClassName("tablinks");
  for (i = 0; i < tablinks.length; i++) {
    tablinks[i].className = tablinks[i].className.replace(" active", "");
  }
  document.getElementById(gameName).style.display = "flex";
  evt.currentTarget.className += " active";
}

//VIDEO
var video;
window.onload = function () {
  video = document.getElementById("myplayer");
};
function play() {
  video.play();
}
function end() {
  console.log("test");
  $(".play-btn").show();
}

$(document).on("click", ".play-btn", function () {
  $(this).hide();
  video.play();
});

// Burger Menu
function initHamburger() {
  const activeClass = "active";
  const $button = $(".burger__button");
  const $navContainer = $(".header__nav");
  const $navItems = $(".header__link");

  function toggleActiveClass() {
    [$button, $navContainer].forEach((item) => item.toggleClass(activeClass));
  }

  $button.click(toggleActiveClass);
  $navItems.click(toggleActiveClass);
}

$(document).ready(function () {
  initHamburger();
  initAnchorScrolling();
});
